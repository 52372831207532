import cookie from 'js-cookie';
import { has } from 'lodash';
import { differenceInCalendarDays } from 'date-fns';
import moment from 'moment';

import { isNil } from './Lang';
import { isBeforeToday, maybeValidDates, stringify as stringifyDate } from './Dates';

// There are other keys, but these are the ones we're concerned with having
const FULL_DESTINATION_KEYS = ['location', 'lat', 'lng', 'city', 'state'];

/**
 * Utilities
 */

export const getNumberOfTravelDays = (travelDates) => {
  let tmpDiff = 1;

  // we have two date types coming into this component !!
  if ( moment.isMoment(travelDates.endDate) ) {
    tmpDiff = travelDates.endDate.diff(travelDates.startDate, 'days');
  } else {
    tmpDiff = differenceInCalendarDays(travelDates.endDate, travelDates.startDate);
  }

  // eslint-disable-next-line no-restricted-globals
  return isNaN(tmpDiff) ? 1 : tmpDiff;
};

export const isValidDestination = (destination) => {
  const { location, lat, lng } = destination || {};

  return !!(location || (lat && lng));
};

export const isFullDestination = destination =>
  !!destination && FULL_DESTINATION_KEYS.every(key => !isNil(destination[key]));

export const isPartialDestination = destination =>
  !isFullDestination(destination) && isValidDestination(destination);

export const isValidTravelDates = (travelDates) => {
  const { startDate, endDate } = maybeValidDates(travelDates) || {};

  if (!startDate || !endDate || isBeforeToday(startDate)) {
    return false;
  }

  return endDate.isAfter(startDate, 'day');
};

export const normalizePreferences = (preferences) => {
  const { numberOfTravelers, travelDates, destination } = preferences;

  const newState = {};


  if (has(preferences, 'numberOfTravelers')) {
    newState.numberOfTravelers = numberOfTravelers;
  }

  if (has(preferences.travelDates, 'startDate') && has(preferences.travelDates, 'endDate')) {
    newState.travelDates = isValidTravelDates(travelDates) ? travelDates : null;
  }

  if (has(preferences, 'destination')) {
    newState.destination = isValidDestination(destination) ? destination : null;
  }

  return newState;
};

const hasLatLng = (destination) => !!(destination?.lat && destination?.lng);
const latLngMatch = (a, b) =>
  parseFloat(a.lat) === parseFloat(b.lat) && parseFloat(a.lng) === parseFloat(b.lng);

const hasLocation = destination => !!destination?.location;
const locationMatch = (destinationA, destinationB) => {
  return destinationA?.location === destinationB?.location;
};

export const destinationsPartiallyMatch = (a, b) => {
  const locationMatches = hasLocation(a) && hasLocation(b) && locationMatch(a, b);

  return locationMatches || (hasLatLng(a) && hasLatLng(b) && latLngMatch(a, b));
};

/**
 * Persistence
 */

const PREFERENCES_COOKIE = 'rv-preferences';

const prepareForStringify = (preferences) => {
  if (!preferences) {
    return undefined;
  }

  const normalized = { ...preferences };

  if (preferences.destination) {
    normalized.destination = isValidDestination(preferences.destination)
      ? preferences.destination
      : undefined;
  }

  if (preferences.travelDates) {
    normalized.travelDates = isValidTravelDates(preferences.travelDates)
      ? {
        startDate: stringifyDate(preferences.travelDates.startDate),
        endDate: stringifyDate(preferences.travelDates.endDate),
      }
      : undefined;
  }

  return normalized;
};

export const getTravelPreferencesFromCookie = () => {
  const preferences = cookie.get(PREFERENCES_COOKIE);

  if (preferences) {
    return JSON.parse(preferences);
  }

  return null;
};

export const saveTravelPreferences = (values) => {
  // expires in 90 days
  cookie.set(PREFERENCES_COOKIE, prepareForStringify(values), { expires: 90 });
};

export const saveTravelDestination = (destination) => {
  // We don't allow clearing the destination
  if (!destination || !isValidDestination(destination)) {
    return;
  }

  saveTravelPreferences({
    ...getTravelPreferencesFromCookie(),
    destination,
  });
};

export const maybeSaveTravelDestination = (destination) => {
  if (!destination || !isValidDestination(destination)) {
    return;
  }

  const { destination: savedDestination } = getTravelPreferencesFromCookie() || {};

  // If a saved destination is missing, save this new one
  if (!savedDestination) {
    saveTravelDestination(destination);
    return;
  }

  // If the saved destination is the partial version of this new one, overwrite it
  if (
    isPartialDestination(savedDestination) &&
    isFullDestination(destination) &&
    destinationsPartiallyMatch(savedDestination, destination)
  ) {
    saveTravelDestination(destination);
  }
};

export const saveTravelDates = (travelDates) => {
  const { startDate, endDate } = travelDates;

  // We allow clearing the travel dates
  if (isValidTravelDates(travelDates) || (!startDate && !endDate)) {
    saveTravelPreferences({
      ...getTravelPreferencesFromCookie(),
      travelDates,
    });
  }
};

export const maybeSaveTravelDates = (travelDates) => {
  if (!travelDates || !isValidTravelDates(travelDates)) {
    return;
  }

  const { travelDates: savedTravelDates } = getTravelPreferencesFromCookie() || {};

  // Only save travel dates here if we don't have saved ones
  if (!savedTravelDates || !isValidTravelDates(savedTravelDates)) {
    saveTravelDates(travelDates);
  }
};

export const saveNumberOfTravelers = (numberOfTravelers) => {
  saveTravelPreferences({
    ...getTravelPreferencesFromCookie(),
    numberOfTravelers: numberOfTravelers || 1,
  });
};

export const updateTowingVehicle = (key, value) => {
  if (!key || !value) { return };

  // they key comes in as towingVehicle.property ie towingVehicle.year
  // this isolates the word after the period which is what we need to determine which object property we should update
  const property = key.split(".").pop();

  const travelPreferencesFromCookie = getTravelPreferencesFromCookie();
  const towingVehicleFromCookie = travelPreferencesFromCookie?.towingVehicle || {};
  const updatedTowingVehicle = {
    // make sure we are not deleting anything already stored in the cookie besides the property value.
    ...towingVehicleFromCookie,
    [property]: value
  }

  saveTravelPreferences({
    ...(travelPreferencesFromCookie || {}),
    towingVehicle: updatedTowingVehicle,
  });
}

export const saveTowingVehicle = (towingVehicle = {}) => {
  saveTravelPreferences({
    ...(getTravelPreferencesFromCookie() || {}),
    towingVehicle,
  });
}
