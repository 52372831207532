import { pick } from 'lodash';
import includes from 'array-includes';

import * as Dates from 'core/helpers/Dates';
import * as Query from 'core/helpers/Query';
import { prune } from 'core/helpers/Object';
import {
  isValidTravelDates,
  isValidDestination,
  destinationsPartiallyMatch,
} from 'core/helpers/TravelPreferences';
import * as RV from 'core/helpers/RV';
import SafeTracking from 'tracking/SafeTracking';
import { isNil } from 'core/helpers/Lang';

/**
 * Private
 */

const endsWith = (str, suffix) => {
  if (isNil(str)) {
    return false;
  }

  return str.toString().indexOf(suffix, str.length - suffix.length) !== -1;
};

const toIntOrNull = x => parseInt(x, 10).toString() || null;

const maybeTransformObjectKeys = (obj, transformers) =>
  Object.keys(obj).reduce((acc, key) => {
    let value = obj[key];

    if (transformers[key]) {
      value = transformers[key](value);
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});

/**
 * Electric Services
 */

const electricServicesWhiteList = ['30', '50'];

export function electricServicesArrayToMap(selectedServices) {
  let selectedArray = [];
  if (!Array.isArray(selectedServices)) {
    selectedArray = selectedServices.split(',');
  } else {
    selectedArray = selectedServices;
  }

  return electricServicesWhiteList.reduce(
    (acc, value) => ({
      ...acc,
      [value]: includes(selectedArray, value),
    }),
    {}
  );
}

function buildElectricServicesFilters(filters) {
  const electricServices = pick(filters, electricServicesWhiteList);
  const selectedFilters = Object.keys(electricServices).filter(service => filters[service]);

  return {
    electricService: selectedFilters,
  };
}

/**
 * Cancellation Policies
 */

const cancellationPoliciesWhiteList = ['Flexible', 'Standard', 'Strict'];

export function cancellationPoliciesArrayToMap(selectedPolicies) {
  let selectedArray = [];
  if (!Array.isArray(selectedPolicies)) {
    selectedArray = selectedPolicies.split(',');
  } else {
    selectedArray = selectedPolicies;
  }

  return cancellationPoliciesWhiteList.reduce(
    (acc, value) => ({
      ...acc,
      [value]: includes(selectedArray, value),
    }),
    {}
  );
}

function buildCancellationPoliciesFilters(filters) {
  const cancellationPolicies = pick(filters, cancellationPoliciesWhiteList);
  const selectedFilters = Object.keys(cancellationPolicies).filter(policy => filters[policy]);

  return {
    cancellationPolicy: selectedFilters,
  };
}

/**
 * Amenities
 */

const amenitiesWhiteList = [
  'Electric Generator',
  'Roof Air Conditioning',
  'Microwave',
  'Shower',
  'Bathroom Sink',
  'Range (Stove)',
  'Navigation',
  'Fire Extinguisher',
  'In Dash Air Conditioning',
  'Refrigerator',
  'TV',
  'DVD Player',
  'Kitchen Sink',
  'Toilet',
  'AM/FM Radio',
  'Rear Vision Camera',
  'Seat Belts',
  'Hot & Cold Water Supply',
  'Slide Out',
  'CD Player',
  'iPod Docking Station',
];

function amenitiesArrayToMap(selectedAmenities) {
  return amenitiesWhiteList.reduce((accum, value) => {
    return {
      ...accum,
      [value]: includes(selectedAmenities, value),
    };
  }, {});
}

function buildAmentitiesFilters(filters) {
  const amenities = pick(filters, amenitiesWhiteList);
  const selectedFilters = Object.keys(amenities).filter(amentity => filters[amentity]);

  return {
    amenities: selectedFilters,
  };
}

/**
 * Destination
 */

const destinationWhitelist = ['location', 'lat', 'lng', 'place_id'];

const destinationTransformers = {
  lat: x => parseFloat(x) || null,
  lng: x => parseFloat(x) || null,
};

function parseDestinationFilters(allFilters) {
  const destination = pick(allFilters, destinationWhitelist);
  const filters = maybeTransformObjectKeys(destination, destinationTransformers);

  return prune(filters);
}

function buildDestinationFilters(filters) {
  const loc = pick(filters, destinationWhitelist);

  return prune({
    lat: loc.lat || undefined,
    lng: loc.lng || undefined,
    location: loc.location || undefined,
    place_id: loc.place_id || undefined,
  });
}

/**
 * Travel Dates
 */

const travelDatesWhitelist = ['startDate', 'endDate'];

function parseTravelDatesFilters(filters) {
  const dates = pick(filters, travelDatesWhitelist);

  return Dates.maybeValidDates(dates);
}

function buildTravelDatesFilters(filters) {
  const dates = pick(filters, travelDatesWhitelist);

  return prune({
    start_date: Dates.stringify(dates.startDate),
    end_date: Dates.stringify(dates.endDate),
  });
}

const towingWeightWhiteList = ['towingWeight'];

function formatTowingVehicle(filters) {
  if (!filters?.towingWeight) {
    return null;
  }

  return { towingWeight: filters.towingWeight };
}

/**
 * Filters
 */

export const defaultAdditionalFilters = {
  amenities: amenitiesArrayToMap([]),
  cancellationPolicy: cancellationPoliciesArrayToMap([]),
  isStationary: false,
  smoking: false,
  toYear: '',
  fromYear: '',
  minLength: '',
  maxLength: '',
  instantBook: false,
  keywords: '',
};

export const defaultFilters = {
  maxMiles: '',
  minMiles: '',
  maxGenHours: '',
  minGenHours: '',
  electricService: electricServicesArrayToMap([]),
  maxWater: '',
  minWater: '',
  maxLength: '',
  minLength: '',
  waterSupply: false,
  min: '',
  max: '',
  rvClass: RV.classOptionsArrayToMap([]),
  sleeps: '',
  towingVehicle: {},
  pets: false,
  delivery: false,
  ...defaultAdditionalFilters,
};

const filtersWhitelist = [
  'page',
  'sort',
  'rvClass',
  'pets',
  'smoking',
  'maxMiles',
  'minMiles',
  'maxGenHours',
  'minGenHours',
  'maxWater',
  'minWater',
  'electricService',
  'waterSupply',
  'min',
  'max',
  'minLength',
  'maxLength',
  'sleeps',
  'fromYear',
  'toYear',
  'instantBook',
  'delivery',
  'isStationary',
  'amenities',
  'keywords',
  'cancellationPolicy',
  'towingVehicle',
  'distance',
  'rvshareMode',
];

const searchFiltersTransformers = {
  page: toIntOrNull,
  maxMiles: toIntOrNull,
  minMiles: toIntOrNull,
  maxGenHours: toIntOrNull,
  minGenHours: toIntOrNull,
  maxWater: toIntOrNull,
  minWater: toIntOrNull,
  min: toIntOrNull,
  max: toIntOrNull,
  minLength: toIntOrNull,
  maxLength: toIntOrNull,
  sleeps: toIntOrNull,
  fromYear: toIntOrNull,
  toYear: toIntOrNull,
  towingWeight: toIntOrNull,
  distance: toIntOrNull,
  rvshareMode: x => !!(x === 'true') || null,
  // Convert to a { [CLASS_NAME]: true } map for use in forms
  rvClass: x => RV.classOptionsArrayToMap(x.split(',')),
  waterSupply: x => !!(x === 'true') || null,
  pets: x => !!(x === 'true') || null,
  smoking: x => !!(x === 'true') || null,
  instantBook: x => !!(x === 'true') || null,
  delivery: x => !!(x === 'true') || null,
  isStationary: x => !!(x === 'true') || null,
  amenities: x => amenitiesArrayToMap(x),
  cancellationPolicy: x => cancellationPoliciesArrayToMap(x),
  electricService: x => electricServicesArrayToMap(x),
};

function parseSearchFilters(allFilters) {
  const otherFilters = pick(allFilters, filtersWhitelist);
  const filters = maybeTransformObjectKeys(otherFilters, searchFiltersTransformers);

  return prune(filters);
}

function buildSearchFilters(filters) {
  const {
    rvClass,
    page,
    sleeps,
    sort,
    max,
    maxMiles,
    minMiles,
    maxGenHours,
    minGenHours,
    maxWater,
    minWater,
    maxLength,
    minLength,
    electricService,
    waterSupply,
    keywords,
    cancellationPolicy,
    towingVehicle,
    ...query
  } = pick(filters, filtersWhitelist);

  if (rvClass) {
    query.rvClass = RV.classOptionsMapToArray(rvClass);
  }

  if (page && parseInt(page, 10) !== 1) {
    query.page = page;
  }

  if (sleeps && parseInt(sleeps, 10) !== 1) {
    query.sleeps = sleeps;
  }

  if (sort && parseInt(sort, 10) !== 1) {
    query.sort = sort;
  }

  if (max && endsWith(max, '+')) {
    delete query.max;
  } else if (max) {
    query.max = max;
  }

  if (maxMiles && endsWith(maxMiles, '+')) {
    delete query.maxMiles;
  } else if (maxMiles) {
    query.maxMiles = maxMiles;
  }

  if (minMiles && minMiles === 0) {
    delete query.minMiles;
  } else if (minMiles) {
    query.minMiles = minMiles;
  }

  if (maxGenHours && endsWith(maxGenHours, '+')) {
    delete query.maxGenHours;
  } else if (maxGenHours) {
    query.maxGenHours = maxGenHours;
  }

  if (minGenHours && minGenHours === 0) {
    delete query.minGenHours;
  } else if (minGenHours) {
    query.minGenHours = minGenHours;
  }

  if (maxWater && endsWith(maxWater, '+')) {
    delete query.maxWater;
  } else if (maxWater) {
    query.maxWater = maxWater;
  }

  if (minWater && minWater === 0) {
    delete query.minWater;
  } else if (minWater) {
    query.minWater = minWater;
  }

  if (maxLength && endsWith(maxLength, '+')) {
    delete query.maxLength;
  } else if (maxLength) {
    query.maxLength = maxLength;
  }

  if (minLength && minLength === 0) {
    delete query.minLength;
  } else if (minLength) {
    query.minLength = minLength;
  }

  if (waterSupply) {
    query.waterSupply = true;
  }

  if (keywords) {
    query.keywords = keywords;
  }

  // at this point, towingVehicle will either be an object with towingWeight param i.e { towingWeight: 8000 }
  // or it will be undefined, so we can trust that if it is falsey it is not the object we are looking for
  // we do this because we want to maintain the towingVehicle object as part of filters so we are consistent between filters and travel preferences
  // but the query in the url is 'towingWeight=8000'
  if (towingVehicle) {
    query.towingWeight = towingVehicle.towingWeight;
    delete query.towingVehicle;
  }

  return Query.prepareForStringify({
    ...query,
    ...buildAmentitiesFilters(filters),
    ...buildCancellationPoliciesFilters(cancellationPolicy),
    ...buildElectricServicesFilters(electricService),
  });
}

/**
 * URL Utilities
 */

export function buildSearchQuery(filters, { addQueryPrefix = false } = {}) {
  const {
    destination,
    travelDates,
    amenities,
    cancellationPolicy,
    electricService,
    ...rest
  } = filters;
  const query = Query.prepareForStringify({
    ...buildDestinationFilters(destination),
    ...buildTravelDatesFilters(travelDates),
    ...buildAmentitiesFilters(amenities),
    ...buildCancellationPoliciesFilters(cancellationPolicy),
    ...buildElectricServicesFilters(electricService),
    ...buildSearchFilters(rest),
  });
  return Query.stringify(query, { snakeCase: true, addQueryPrefix });
}

export function buildSearchLocation(filters) {
  return {
    pathname: filters.pathname || '/rv-rental',
    search: buildSearchQuery(filters),
  };
}

export function buildSearchUrl(filters) {
  const { pathname, search } = buildSearchLocation(filters);

  return `${pathname}?${search}`;
}

const pluckFiltersFromQueryString = qs =>
  pick(Query.parse(qs, { camelCase: true }), [
    ...towingWeightWhiteList,
    ...destinationWhitelist,
    ...travelDatesWhitelist,
    ...filtersWhitelist,
  ]);

export function getFiltersFromQueryString(qs) {
  const filters = pluckFiltersFromQueryString(qs);
  const destination = parseDestinationFilters(filters);
  const travelDates = parseTravelDatesFilters(filters);
  const towingVehicle = formatTowingVehicle(filters);

  return prune({
    ...parseSearchFilters(filters),
    towingVehicle,
    destination: isValidDestination(destination) ? destination : null,
    travelDates: isValidTravelDates(travelDates) ? travelDates : null,
  });
}

export const isSame = (a, b) => buildSearchQuery(a) === buildSearchQuery(b);

export function calculateNewLocation(oldFilters, newFilters) {
  const newLocation = {
    search: buildSearchQuery(newFilters),
  };

  // If destination changed (location, lat, lng) make sure pathname is /rv-rental
  // this is helpful for forcing the user *off* of a regional lander if they change
  // their destination and onto the regular search page
  if (!destinationsPartiallyMatch(oldFilters.destination, newFilters.destination)) {
    newLocation.pathname = '/rv-rental';
  }

  return newLocation;
}

export const isFilterSelected = (filters, filterKeys = [], getSelectedCount = false) => {
  const filterIsSelected = (filter, defaults) => {
    return !!filter && filter !== defaults;
  };

  if (getSelectedCount) {
    return filterKeys.reduce((total, filter) => {
      if (filters[filter] && typeof filters[filter] === 'object') {
        const filtersObj = filters[filter];
        const selected = Object.keys(filtersObj).filter(filterKey =>
          filterIsSelected(filtersObj[filterKey], defaultFilters[filter][filterKey])
        );

        return selected.length ? total + selected.length : total;
      }

      const isSelected = filterIsSelected(filters[filter], defaultFilters[filter]);
      return isSelected ? total + 1 : total;
    }, 0);
  }

  return filterKeys.some(filter => {
    if (filters[filter] && typeof filters[filter] === 'object') {
      const filtersObj = filters[filter];
      return Object.keys(filtersObj).some(filterKey =>
        filterIsSelected(filtersObj[filterKey], defaultFilters[filter][filterKey])
      );
    }

    return filterIsSelected(filters[filter], defaultFilters[filter]);
  });
};

export const clearAllFilters = (filters, excludeFromClear = []) => {
  const filtersToKeep = pick(filters, excludeFromClear.concat('destination', 'travelDates'));

  return {
    ...defaultFilters,
    ...filtersToKeep,
  };
};

export const kitchenAmenities = [
  'Microwave',
  'Range (Stove)',
  'Refrigerator',
  'Kitchen Sink',
  'Slide Out',
];

export const bathroomAmenities = ['Shower', 'Bathroom Sink', 'Toilet'];

export const entertainmentAmenities = [
  'CD Player',
  'iPod Docking Station',
  'TV',
  'DVD Player',
  'AM/FM Radio',
];

export const temperatureAmenities = ['Hot & Cold Water Supply', 'In Dash Air Conditioning'];

export const otherAmenities = [
  'Electric Generator',
  'Seat Belts',
  'Navigation',
  'Rear Vision Camera',
];

export const amenitiesCategories = {
  Kitchen: kitchenAmenities,
  Bathroom: bathroomAmenities,
  Entertainment: entertainmentAmenities,
  Temperature: temperatureAmenities,
  Other: otherAmenities,
};

/**
 * This takes filters array and previous filter array
 * and determines the newly selected filter, transforms it and sends to safeTracking
 */
export const trackNewFilters = (filters, prevFilters) => {
  if (!filters || !prevFilters) return [];

  const newFilter = [];

  try {
    Object.keys(filters).forEach(filter => {
      let tempFilter = {};
      // can possibly get a null (ex: towingVehicle) which throws error
      if (!filters[filter]) return;

      // check if filter is in prevFilter regardless of value (empty strings)
      if (filter in prevFilters) {
        // if filter is an object loop through it (example: amenities)
        if (typeof filters[filter] === 'object') {
          Object.keys(filters[filter]).forEach(subFilter => {
            // if filter[x][y] is in prevFilters, check for a match
            if (subFilter in prevFilters[filter]) {
              // if filter[x][y] does not match prevfilter[x][y] return it
              if (prevFilters[filter][subFilter] !== filters[filter][subFilter]) {
                tempFilter = {
                  filterCategory: filter,
                  filterName: subFilter,
                  filterValue: filters[filter][subFilter],
                };
              }
              // if filter[x][y] is not in prevFilters[x] and NOT FALSE return it
              // this check is basically for first page load when there is no prevFilters,
              // we don't want to send any filters that were loaded as empty.
            } else if (filters[filter][subFilter]) {
              tempFilter = {
                filterCategory: filter,
                filterName: subFilter,
                filterValue: filters[filter][subFilter],
              };
            }
          });
          // if filter[x] does not match prevfilter[x] return it
        } else if (filters[filter] !== prevFilters[filter]) {
          tempFilter = {
            filterName: filter,
            filterValue: filters[filter],
          };
        }
        // if filter was not in prevFilter and is an object loop through it (example: amenities)
      } else if (typeof filters[filter] === 'object') {
        Object.keys(filters[filter]).forEach(subFilter => {
          // if there's a truthy valie to this key, this is what we want.
          if (filters[filter][subFilter]) {
            tempFilter = {
              filterCategory: filter,
              filterName: subFilter,
              filterValue: filters[filter][subFilter],
            };
          }
        });
        // if filter was not in prevFilter and is truthy
      } else if (filters[filter]) {
        tempFilter = {
          filterName: filter,
          filterValue: filters[filter],
        };
      }

      if (Object.keys(tempFilter).length > 0) {
        newFilter.push(tempFilter);
      }
    });

    const trackedFilter = newFilter[0];
    const { filterCategory, filterValue } = trackedFilter;
    let { filterName } = trackedFilter;
    let filterValueSelected = '';
    let minValue = '';
    let maxValue = '';

    // if we get to this point and filterValue is still an object
    // "select all" was clicked. we can use this logic to stop
    // the tracking event. "Select All" is handled onClick elsewhere
    if (typeof filterValue === 'object') {
      return;
    }

    // location filter should be a "search executed" track, not a "filter used"
    if (filterName === 'location' || filterName === 'country') {
      return;
    }

    // transform our filter into data the tracker is looking for
    switch (filterName) {
      case 'maxMiles':
        if (filterValue === -1) {
          filterName = 'Unlimited Miles Included';
          maxValue = filterValue;
          break;
        }
        filterName = 'Miles Included';
        maxValue = filterValue;
        break;
      case 'minMiles':
        filterName = 'Miles Included';
        minValue = filterValue;
        break;
      case 'maxGenHours':
        if (filterValue === -1) {
          filterName = 'Unlimited Generator Hours Included';
          maxValue = filterValue;
          break;
        }
        filterName = 'Generator Hours Included';
        maxValue = filterValue;
        break;
      case 'minGenHours':
        filterName = 'Generator Hours Included';
        minValue = filterValue;
        break;
      case 'maxWater':
        filterName = 'Fresh Water Tank Capacity';
        maxValue = filterValue;
        break;
      case 'minWater':
        filterName = 'Fresh Water Tank Capacity';
        minValue = filterValue;
        break;
      case 'min':
        filterName = 'Price';
        minValue = filterValue;
        break;
      case 'max':
        filterName = 'Price';
        maxValue = filterValue;
        break;
      case 'electricService':
        filterName = 'Electric Service';
        filterValueSelected = `${filterValue} Amp`;
        break;
      case 'waterSupply':
        filterName = 'Hot and Cold Water Supply';
        filterValueSelected = 'Has Hot and Cold Water Supply';
        break;
      case 'delivery':
        filterName = 'Offers Delivery';
        filterValueSelected = 'Offers Delivery';
        break;
      case 'isStationary':
        filterName = 'Rental Options';
        filterValueSelected = filterValue && 'Offers Stationary Rental';
        break;
      case 'pets':
        filterName = 'Allows Pets';
        filterValueSelected = 'Allows Pets';
        break;
      case 'smoking':
        filterName = 'Rental Rules';
        filterValueSelected = filterValue && 'Allows Smoking';
        break;
      case 'minLength':
        filterName = 'Length';
        minValue = filterValue;
        break;
      case 'maxLength':
        filterName = 'Length';
        maxValue = filterValue;
        break;
      case 'fromYear':
        filterName = 'Year';
        minValue = filterValue;
        break;
      case 'toYear':
        filterName = 'Year';
        maxValue = filterValue;
        break;
      case 'keywords':
        filterName = 'Keywords';
        filterValueSelected = filterValue;
        break;
      case 'instantBook':
        filterName = 'Instant Book';
        filterValueSelected = filterValue && 'Offers Instant Booking';
        break;
      case 'sleeps':
        filterName = 'Sleeps';
        filterValueSelected = filterValue;
        break;
      default:
        break;
    }

    // Transform Amenities and RV Class
    if (filterCategory) {
      filterValueSelected = filterValue && filterName;
      // if this is amenities we need to get category name
      if (filterCategory === 'amenities') {
        Object.keys(amenitiesCategories).forEach(category => {
          if (amenitiesCategories[category].indexOf(filterName) >= 0) {
            filterName = category;
          }
        });
      } else if (filterCategory === 'cancellationPolicy') {
        filterValueSelected = filterValue ? filterName : false;
        filterName = 'Cancellation Type';
      } else if (filterCategory === 'rvClass') {
        if (RV.isTowable({ name: filterName })) {
          filterName = 'Towable RV';
        } else if (RV.isDrivable({ name: filterName })) {
          filterName = 'Drivable RV';
        }
      }
    }

    const hasYearMakeModelWeight =
      Array.isArray(newFilter) &&
      newFilter.some(obj => obj.filterCategory === 'towingVehicle') &&
      !!filterValue &&
      !!filters?.towingVehicle?.year &&
      !!filters?.towingVehicle?.make &&
      !!filters?.towingVehicle?.model;

    if (hasYearMakeModelWeight) {
      filterName = 'Towing Vehicle';
      filterValueSelected = filterName;
    }

    // if the value is FALSE then the filter was unselected and we don't track
    if (!filterValueSelected && !minValue && !maxValue) {
      return;
    }

    SafeTracking.track(
      'Search Filter Used',
      {
        filterName,
        filterValueSelected,
        minValue,
        maxValue,
        ...(hasYearMakeModelWeight && {
          towingVehicle: {
            towingYear: filters.towingVehicle.year,
            towingMake: filters.towingVehicle.make,
            towingModel: filters.towingVehicle.model,
          },
        }),
      },
      { rvsIntegrations: { all: false, segment: true } }
    );
  } catch (e) {
    // fail gracefully
    // eslint-disable-next-line no-console
    console.warn('trackNewFilters', e.message);
  }
};
